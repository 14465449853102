import { Alert, Col, Divider, FormInstance, Row, Statistic } from "antd";
import { useTranslation } from "react-i18next";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { formatLocaleCurrency, formatLocaleDate, formatPhoneNumber } from "../../../../../common/utils/formatUtils";
import { cssVariables } from "../../../../../common/utils/utils";
import { ClientsListDrawerView } from "../../../../client/components/drawers/ClientsListDrawerView";
import { ClientFormType, ClientType } from "../../../../client/enums";
import { createClientsData } from "../../../../client/utils";
import { InstitutionEnum } from "../../../../institution/enums";
import { TravelInsuranceType } from "../../travel/enums";
import type { GenGainersData } from "../../types";
import type { TravelCalcSummaryViewData } from "./CalcSummaryModal";
import { GainersCommissionRateSection } from "./GainersCommissionRateSection";

type Props = {
  data: TravelCalcSummaryViewData;
  divideCommission: boolean;
  setDivideCommission: (value: boolean) => void;
  gainersDataForm: FormInstance<GenGainersData>;
};

const SPAN_LABEL = 11;
const SPAN_VALUE = 13;

const TravelCalcSummaryView = ({
  data: { calcData, clients, formData, selectedInsurance },
  divideCommission,
  gainersDataForm,
  setDivideCommission
}: Props) => {
  const { t } = useTranslation();

  const axaAssistanceYearFamilyInsurance =
    selectedInsurance.insuranceInstitution.institutionEnum === InstitutionEnum.AXA_ASSISTANCE &&
    calcData.generalData.insuranceType === TravelInsuranceType.YEAR &&
    calcData.discountsData?.familyInsurance &&
    (clients.policyHolder?.type === ClientType.NATURAL || clients.policyHolder);

  return (
    <div>
      <Row>
        <Col flex={1}>
          <Statistic
            className="statistic-small"
            title={t("calc.helpers.product")}
            value={t("calc.travel.enums.insuranceType." + calcData.generalData.insuranceType)}
          />
        </Col>

        <Col flex={1}>
          <Statistic
            className="statistic-small"
            title={t("common.insuranceInstitution")}
            value={selectedInsurance.insuranceInstitution.name}
          />
        </Col>

        {selectedInsurance.coverage ? (
          <Col flex={1}>
            <Statistic
              className="statistic-small"
              title={<span style={{ textTransform: "capitalize" }}>{t("calc.travel.sections.coverage")}</span>}
              value={selectedInsurance.coverage}
            />
          </Col>
        ) : undefined}

        <Col flex={1}>
          <Statistic
            className="statistic-small"
            title={t("calc.travel.enums.territorialValidity._label")}
            value={t("calc.travel.enums.territorialValidity." + calcData.generalData.territorialValidity)}
          />
        </Col>

        <Col flex={1}>
          {calcData.generalData.riskGroup ? (
            <Statistic
              className="statistic-small"
              title={t("calc.travel.enums.riskGroup._label")}
              value={t("calc.travel.enums.riskGroup." + calcData.generalData.riskGroup)}
            />
          ) : undefined}
        </Col>
      </Row>
      <Row className="margin-top-small">
        <Col flex={1}>
          <Divider orientation="left">{t("calc.travel.attrs.clientsData._label")}</Divider>
          <ClientsListDrawerView
            clientsData={createClientsData([
              {
                client: clients.policyHolder,
                type: ClientFormType.POLICY_HOLDER
              }
            ])}
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Row>
            <Col span={5}>{t("calc.travel.attrs.clientsData.policyHolderPhone")}</Col>
            <Col span={19}>{formatPhoneNumber(formData.clientsData.policyHolderPhone)}</Col>
          </Row>
          <Row>
            <Col span={5}>{t("calc.travel.attrs.clientsData.policyHolderEmail")}</Col>
            <Col span={19}>{formData.clientsData.policyHolderEmail}</Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <Row>
            {axaAssistanceYearFamilyInsurance ? (
              <Alert
                style={{ marginTop: "20px" }}
                type="info"
                showIcon
                message={t("calc.travel.helpers.axaAssistanceYearFamilyInsuranceDesc")}
              />
            ) : (
              <>
                <Col span={5}>{t("calc.travel.sections.insuredPersons")}</Col>
                <Col span={19}>
                  {formData.clientsData.insuredClients
                    ?.map(client => `${client.firstName} ${client.lastName} (${formatLocaleDate(client.birthDate)})`)
                    .join(", ")}
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>

      <Row className="margin-top-small">
        <Col span={11}>
          <Divider orientation="left">{t("calc.travel.sections.coverages")}</Divider>
          {selectedInsurance.data.appliedCoverages.map(coverage => {
            return (
              <Row key={coverage.type}>
                <Col span={SPAN_LABEL}>{t("contract.enums.insuranceCoverageType." + coverage.type)}</Col>
                <Col span={SPAN_VALUE}>
                  <AntIcon type="check-circle-two-tone" twoToneColor={cssVariables.colorGreen} />
                </Col>
              </Row>
            );
          })}
          {selectedInsurance.data.missingCoverages.map(coverage => {
            return (
              <Row key={coverage}>
                <Col span={SPAN_LABEL}> {t("contract.enums.insuranceCoverageType." + coverage)}</Col>
                <Col span={SPAN_VALUE}>
                  <AntIcon type="close-circle-two-tone" twoToneColor={cssVariables.colorRed} />
                </Col>
              </Row>
            );
          })}
        </Col>

        <Col span={1} />

        <Col span={12}>
          <Divider orientation="left">{t("calc.travel.results.additionalInfo")}</Divider>
          <Row>
            <Col span={SPAN_LABEL}>{t("calc.travel.attrs.generalData.effectiveBeginningDate")}</Col>
            <Col span={SPAN_VALUE}>{formatLocaleDate(calcData.generalData.effectiveBeginningDate)}</Col>
          </Row>
          {calcData.generalData.effectiveEndDate ? (
            <Row>
              <Col span={SPAN_LABEL}> {t("calc.travel.attrs.generalData.effectiveEndDate")}</Col>
              <Col span={SPAN_VALUE}>{formatLocaleDate(calcData.generalData.effectiveEndDate)}</Col>
            </Row>
          ) : undefined}
          {calcData.cancellationData ? (
            <Row>
              <Col span={SPAN_LABEL}>{t("calc.travel.attrs.cancellationData.tripPrice")}</Col>
              <Col span={SPAN_VALUE}>{formatLocaleCurrency(calcData.cancellationData?.tripPrice)}</Col>
            </Row>
          ) : undefined}
          <Row>
            <Col span={SPAN_LABEL}>
              {t(
                calcData.generalData.insuranceType === TravelInsuranceType.YEAR
                  ? "contract.attrs.annualPremium"
                  : "calc.travel.results.annualPremium"
              )}
            </Col>
            <Col span={SPAN_VALUE}>{formatLocaleCurrency(selectedInsurance.data.annualPremium)}</Col>
          </Row>
        </Col>
      </Row>

      <div className="margin-top-small">
        <GainersCommissionRateSection
          divideCommission={divideCommission}
          setDivideCommission={setDivideCommission}
          gainersDataForm={gainersDataForm}
        />
      </div>
    </div>
  );
};

export default TravelCalcSummaryView;
